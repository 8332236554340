import React, { useState } from 'react';
import Autocomplete from 'react-google-autocomplete';

export default function AddressAutocomplete() {
  const [isFullAddress, setIsFullAddress] = useState(false);
  const [addressComponents, setAddressComponents] = useState(null);

  const setFormAddress = value => {
    document.querySelector('#property_address').value = value;
  };

  return (
    <div className="autocomplete-inputs-wrapper">
      <Autocomplete
        className="autocomplete-input"
        onPlaceSelected={place => {
          setAddressComponents(
            place.address_components.filter(comp => {
              return (
                comp.types.includes('street_number') ||
                comp.types.includes('route') ||
                comp.types.includes('locality') ||
                comp.types.includes('administrative_area_level_1') ||
                comp.types.includes('country') ||
                comp.types.includes('postal_code')
              );
            })
          );
          setIsFullAddress(true);
          setFormAddress(place.formatted_address);
        }}
        placeholder="Enter Address Here"
        onKeyDown={e => {
          if (e.which === 8) setIsFullAddress(false); // Backspace
          if (e.which === 13 && !isFullAddress) e.preventDefault(); // Enter
        }}
        types={['address']}
      />

      <input
        type="text"
        placeholder="Unit #"
        className="autocomplete-input"
        onChange={e => {
          if (addressComponents) {
            const mockedAddressComponent = {
              long_name: e.target.value,
              short_name: e.target.value,
              types: ['Unit Number'],
            };
            const addressComponentsCopy = [...addressComponents];
            addressComponentsCopy.splice(2, 0, mockedAddressComponent);
            const [
              streetNumber,
              route,
              unitNumber,
              city,
              state,
              county,
              zipcode,
            ] = addressComponentsCopy.map(comp => comp.short_name);
            setFormAddress(
              `${streetNumber} ${route} ${unitNumber}, ${city}, ${state} ${zipcode}, ${county}`
            );
          }
        }}
      />
    </div>
  );
}
